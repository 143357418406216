
.custom-data-table-container {
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #999999 #ffffff;
}



.custom-data-table-container::-webkit-scrollbar {
  width: 10px;
}

.custom-modal {
  /* Custom modal styles */
  max-width: 700px;
  min-width: 500px;
  margin: 0 auto;
  top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal-header {
  /* Custom title card styles */
  background-color: #f0f0f0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.custom-data-table-container::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.custom-data-table-container::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
}


.btn-acao {
  padding: 0px 0px 0px 0px;
  box-shadow: none;
  background-color: transparent;
  border: none;
  .edit {
    color: #4d7ef7;
  }
  .remove {
    color: #f74d4d;
  }
}
.btn-acao:hover {
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.bg-login {
  background-color: #4d7ef7;
}


.btn-lab {
  padding: 0px 0px 0px 0px;
  box-shadow: none;
  background-color: transparent;
  border: none;
  .edit {
    color: #f5ca67;
  }
  .remove {
    color: #f74d4d;
  }
}
.btn-lab:hover {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.btn-media {
  padding: 0px 0px 0px 0px;
  box-shadow: none;
  background-color: transparent;
  border: none;
  color: #f74d4d;
}
.footer-login {
  color: #fff;
  .main-text {
    color: #fff;
  }
  a {
    color: #b1bbc4;
  }
  a:hover {
    color: #e9ecef;
  }
  .nav-item:hover {
    color: #e9ecef;
  }
}

/*################# SIDEBAR ###################*/
.bg-sidebar {
  background-color: #4d7ef7;
  color: #b1bbc4;
}
.logo-sidebar {
  margin-right: 10px;
  margin-left: 8px;
  height: 59px;
  font-size: 26px;
  width: 27px !important;
}
.logo-menu {
  font-weight: 600;
  font-size: medium !important;
}
.main-navbar {
  padding: 0 5px;
  li {
    border-bottom: solid 1px #fff;
    a {
      padding: 0px !important;
      color: #dee3e7 !important;
      svg {
        color: #f7d183 !important;
      }
    }
    a:hover {
      color: #3c3f42 !important;
      svg {
        color: #f3c466 !important;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .chev-sidebar {
    right: 29px !important;
    position: absolute;
  }
}
@media only screen and (min-width: 768px) {
  .chev-sidebar {
    position: absolute;
    left: 220px;
  }
}
.sub-navbar {
  margin-left: 1rem !important;
  margin-bottom: 3px !important;
  width: 88%;
  li {
    margin-bottom: 7px;
    border-bottom: solid 1px #dee3e742;
    a {
      padding-left: 6px !important;
    }
  }
}
.ico-hide {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 7px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #073d6b;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #011527;
}




/*################# SIDEBAR ###################*/
.btn-zoo {
  background: #f5ca67;
  color: #00f;
}
.btn-zoo:hover {
  background: #f7d587;
  color: #00f;
}

/*################# CONTAINER ###################*/

.main-zoo {
  margin-top: 50px !important;
  min-height: 100px;
}

.btn-zoo-alt {
  background: #00f;
  color: #fff;
}
.btn-zoo-alt:hover {
  background: rgb(23, 23, 255);
  color: #f5ca67;
}

.btn-zoo-suc {
  background: #298a40;
  pointer-events: none;
  color: #fff;
}
.btn-zoo-suc:hover {
  background:#246633;
  pointer-events: none;
  color: #f5ca67;
}

.btn-zoo-war {
  background: #f5ca67;
  color: #00f;
}
.btn-zoo-war:hover {
  background:#f3c14b;
  color: #00f;
}

.rcw-conversation-container > .rcw-header {
  background-color: #f5ca67 !important;
}

.rcw-message > .rcw-response {
  background-color: #f8f9fe !important;
  color: black !important;
}

.rcw-message > .rcw-client {
  background-color: #4d7ef7 !important;
  color: white !important;
}

.rcw-launcher {
  background-color: #f5ca67 !important;
}

.zoo-feed-photo {
  display: block !important;
  text-indent: -9999px !important;
  width: 100px !important;
  height: 82px !important;
  background: url(../img/feed-foto.svg) !important;
  background-size: 100px 82px !important;
}

.zoo-feed-photo-full {
  display: block !important;
  pointer-events: none;
  text-indent: -9999px !important;
  opacity: 0.4;
  width: 100px !important;
  height: 82px !important;
  background: url(../img/feed-foto.svg) !important;
  background-size: 100px 82px !important;
}

.zoo-feed-video {
  display: block !important;
  text-indent: -9999px !important;
  width: 100px !important;
  height: 82px !important;
  background: url(../img/video.svg) !important;
  background-size: 100px 82px !important;
}


.zoo-video-container {
	background: #fff;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
	position: relative;
	border-radius: 10px;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 10px auto;
	transition: all 0.3s ease-in;
}

.btn-vacation:hover {
  text-decoration: none;
  color: #fff;
}
.btn-vacation {
  text-decoration: none;
  background-color: #455a64;
  color: #fff;
  width: 401px;
  padding: 10px 10px 10px 10px;
  height: 109px;
  border-radius: 4px;
  .icon-container {
    background-color: #92cab1;
    float: left;
    border-radius: 50%;
    font-size: 52px;
    padding: 5px 10px;
    svg {
      color: #fff;
    }
  }
  span {
    float: right;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    margin-top: 23px;
  }

  .txt-contact 
  {
    white-space: nowrap;
    font-size: small !important;
  }
  
}
